import {
  ascendantBlot,
  AttributeMap,
  Blot,
  type BlotName,
  bubbleFormats,
  BubbleFormatsMode,
  Delta,
  type DeltaAttributes,
  type DeltaInsertAttributes,
  descendantBlots,
  generateTcCtx,
  getLineFormat,
  getOpLength,
  getRowCells,
  getTableIndexedTable,
  getTableLayoutIndexOf,
  hasAttributes,
  isLine,
  isScopeExactLineBlot,
  type ModOp,
  type MountedEditor,
  type SuccessSelectionRange,
  Table,
  TableCell,
  TableRow,
  TrackingChangesDelta,
  Condition
} from '@avvoka/editor'
import {
  BitArray,
  clone,
  cloneObjectWithEmptyValues,
  equal,
  getObjectKeys,
  orderObjectKeys,
  Source,
  TextTools,
  UUID
} from '@avvoka/shared'
import type { EditorStoreType } from '@stores/features/editor.store'
import type { RequireExactlyOne } from 'type-fest'

export const addRow = (
  top: boolean,
  editor: MountedEditor,
  rowId = TextTools.randomText(6)
) => {
  const selection = editor.selection.validValue
  if (selection) {
    const td = ascendantBlot(
      selection.blotAtStart,
      (blot) => blot.statics.blotName === 'td'
    ) as TableCell
    if (td == null) return false

    const row = ascendantBlot(
      td,
      (blot) => blot.statics.blotName === 'tr'
    ) as TableRow
    let updateDelta = new Delta()

    if (top) updateDelta.retain(row.index())
    else updateDelta.retain(row.index() + row.length())

    const formats = bubbleFormats(row, BubbleFormatsMode.LINE_CONTAINERS_ONLY)
    const cells = getRowCells(row)
    for (let i = 0; i < cells.length; i++) {
      const attributes: DeltaInsertAttributes = {
        ...AttributeMap.compose(formats, { tr: { 'data-row-id': rowId } }),
        td: {
          ...cells[i].toDeltaFormat()!['td'],
          'data-table-id': td.attributes['data-table-id'],
          'data-row-id': rowId,
          'data-cell-id': TextTools.randomId()
        },
        block: {
          'data-uuid': UUID.new()
        }
      }
      delete attributes.td.rowspan

      updateDelta.insert('\n', attributes)
    }

    if (editor.options.mode === 'document') {
      const tcDelta = TrackingChangesDelta.insertDelta(
        updateDelta,
        editor.negotiation.user,
        Date.now(),
        TextTools.randomText(6),
        false
      )
      updateDelta = updateDelta.compose(tcDelta)
    }

    editor.update(
      updateDelta,
      new BitArray().set(Source.USER).set(Source.TRACKING_CHANGES)
    )
    return true
  }
  return false
}

export const addColumn = (left: boolean, editor: MountedEditor) => {
  const selection = editor.selection.validValue
  if (selection) {
    const td = ascendantBlot(
      selection.blotAtStart,
      (blot) => blot.statics.blotName === 'td'
    ) as TableCell
    if (td == null) return false

    const table = ascendantBlot(
      td,
      (blot) => blot.statics.blotName === 'table'
    ) as Table
    const layout = getTableIndexedTable(table)
    const { cellIndex } = getTableLayoutIndexOf(table, td, layout)

    let updateDelta = new Delta()
    let lastIndex = 0
    for (let rowIndex = 0; rowIndex < layout.length; rowIndex++) {
      const cell = layout[rowIndex][cellIndex]
      const formats = bubbleFormats(
        cell.parent!,
        BubbleFormatsMode.LINE_CONTAINERS_ONLY
      )

      const format: DeltaInsertAttributes = {
        ...AttributeMap.compose(formats, {
          tr: { 'data-row-id': cell.attributes['data-row-id'] }
        }),
        ...AttributeMap.compose(
          { td: cell.toDeltaFormat()!['td'] },
          {
            td: {
              colspan: null,
              rowspan: null,
              'data-cell-id': TextTools.randomId()
            }
          }
        ),
        block: {
          'data-uuid': UUID.new()
        }
      }

      if (left) {
        updateDelta.retain(cell.index() - lastIndex).insert('\n', format)
        lastIndex = cell.index()
      } else {
        updateDelta
          .retain(cell.index() + cell.length() - lastIndex)
          .insert('\n', format)
        lastIndex = cell.index() + cell.length()
      }
    }

    if (editor.options.mode === 'document') {
      const tcDelta = TrackingChangesDelta.insertDelta(
        updateDelta,
        editor.negotiation.user,
        Date.now(),
        TextTools.randomText(6),
        false
      )
      updateDelta = updateDelta.compose(tcDelta)
    }

    editor.update(
      updateDelta,
      new BitArray().set(Source.USER).set(Source.TRACKING_CHANGES)
    )
    return true
  }
  return false
}

export const removeTable = (name: string, editor: MountedEditor) => {
  const selection = editor.selection.validValue
  if (selection) {
    const table = ascendantBlot(
      selection.blotAtStart,
      (blot) => blot.statics.blotName === 'table'
    ) as Table
    // We cannot insert table in table
    if (table == null) return false

    const updateDelta = new Delta().retain(table.index())
    if (editor.options.mode === 'document') {
      // Change insertion format for deletion to prevent breaking table
      table.getDelta().ops.forEach((op) => {
        const formats: DeltaAttributes = {}
        const len = getOpLength(op)
        const ctx = {
          changeId: TextTools.randomText(6),
          modTime: Date.now()
        }
        if (isLine(op)) {
          if (hasAttributes(op)) {
            Object.entries(op.attributes).forEach(([attrName, attrValue]) => {
              if (attrValue != null && typeof attrValue === 'object') {
                const tcData = attrValue['data-tc']
                if (tcData === 'insert') {
                  formats[attrName] = null
                }
              }
            })
          }
          const lineFormat = getLineFormat(op.attributes!) as string
          updateDelta.retain(
            len,
            AttributeMap.compose(
              {
                [lineFormat]: generateTcCtx(
                  editor.negotiation.user,
                  true,
                  false,
                  ctx.modTime,
                  ctx.changeId
                )
              } as unknown as DeltaAttributes,
              {
                [lineFormat]: { 'nl-insert': null }
              }
            )
          )
        } else {
          updateDelta.retain(
            len,
            AttributeMap.compose(
              generateTcCtx(
                editor.negotiation.user,
                false,
                false,
                ctx.modTime,
                ctx.changeId
              ) as unknown as DeltaAttributes,
              {
                insert: null
              }
            )
          )
        }
      })
    } else {
      updateDelta.delete(table.length())
    }

    editor.update(
      updateDelta,
      new BitArray().set(Source.USER).set(Source.TRACKING_CHANGES)
    )
    return true
  }
  return false
}

export const addTable = (
  name: string,
  rows: number,
  cells: number,
  editor: MountedEditor
) => {
  const selection = editor.selection.validValue
  if (selection) {
    // Prevent adding table to condition or numbering, which are not supported (temp fix for #744 and #919)
    if (
      editor.options.mode === 'document' &&
      ascendantBlot(selection.blotAtStart, (blot) =>
        ['condition', 'numbered'].includes(blot.statics.blotName)
      ) !== null
    ) {
      return false
    }

    // Prevent adding table into a table
    if (
      ascendantBlot(
        selection.blotAtStart,
        (blot) => blot.statics.blotName === 'table'
      ) !== null
    ) {
      return false
    }

    const updateDelta = new Delta().retain(selection.start)
    const block = ascendantBlot(selection.blotAtStart, isScopeExactLineBlot)!
    const tableId = TextTools.randomId()
    for (let r = 0; r < rows; r++) {
      const rowId = TextTools.randomId()
      for (let c = 0; c < cells; c++) {
        const cellId = TextTools.randomId()
        updateDelta.insert('\n', {
          ...bubbleFormats(block, BubbleFormatsMode.LINE_CONTAINERS_ONLY),
          table: {
            'data-table-id': tableId
          },
          tr: {
            'data-table-id': tableId,
            'data-row-id': rowId
          },
          td: {
            'data-table-id': tableId,
            'data-row-id': rowId,
            'data-cell-id': cellId
          },
          block: {
            'data-uuid': UUID.new()
          }
        })
      }
    }

    if (editor.options.mode === 'document') {
      const condition = ascendantBlot(
        selection.blotAtStart,
        (b) => b.statics.blotName === 'condition'
      )
      if (condition != null) {
        // TODO(sionzee): rework to update delta
        condition.unwrap({ registry: editor.registry, options: editor.options })

        updateDelta.ops.forEach((op) => {
          const modOp = op as ModOp & { attributes: DeltaAttributes }
          if (isLine(op) && hasAttributes(op)) {
            delete modOp.attributes.condition
          }
        })
      }
    }

    editor.update(updateDelta, new BitArray().set(Source.USER))
    return true
  }
  return false
}

export const removeRow = (name: string, editor: MountedEditor) => {
  const selection = editor.selection.validValue
  if (selection) {
    const td = ascendantBlot(
      selection.blotAtStart,
      (blot) => blot.statics.blotName === 'td'
    ) as TableCell
    if (td == null) return false

    const row = ascendantBlot(
      td,
      (blot) => blot.statics.blotName === 'tr'
    ) as TableRow
    let updateDelta: Delta = new Delta().retain(row.index())
    if (editor.options.mode === 'document') {
      const ctx = {
        changeId: TextTools.randomText(6),
        modTime: Date.now()
      }
      updateDelta = updateDelta.concat(
        TrackingChangesDelta.removeDelta(
          row.getDelta(),
          editor.negotiation.user,
          ctx.modTime,
          ctx.changeId
        )
      )
    } else {
      updateDelta.delete(row.length())
    }

    editor.update(
      updateDelta,
      new BitArray().set(Source.USER).set(Source.TRACKING_CHANGES)
    )
    return true
  }
  return false
}

export const removeColumn = (name: string, editor: MountedEditor) => {
  const selection = editor.selection.validValue
  if (selection) {
    const td = ascendantBlot(
      selection.blotAtStart,
      (blot) => blot.statics.blotName === 'td'
    ) as TableCell
    if (td == null) return false

    const table = ascendantBlot(
      td,
      (blot) => blot.statics.blotName === 'table'
    ) as Table
    const layout = getTableIndexedTable(table)
    const { cellIndex } = getTableLayoutIndexOf(table, td, layout)

    const ctx = {
      changeId: TextTools.randomText(6),
      modTime: Date.now()
    }
    let updateDelta = new Delta()
    let lastIndex = 0
    for (let i = 0; i < layout.length; i++) {
      const cell = layout[i][cellIndex]

      // in case the table's layout is broken and we want to fix it manually
      if (!cell) continue

      updateDelta.retain(cell.index() - lastIndex)
      if (editor.options.mode === 'document') {
        descendantBlots(cell, 0, cell.length(), isScopeExactLineBlot).forEach(
          (line) => {
            updateDelta = updateDelta.concat(
              TrackingChangesDelta.removeDelta(
                line.getDelta(),
                editor.negotiation.user,
                ctx.modTime,
                ctx.changeId
              )
            )
          }
        )
      } else {
        updateDelta.delete(cell.length())
      }

      lastIndex = cell.index() + cell.length()
    }

    editor.update(
      updateDelta,
      new BitArray().set(Source.USER).set(Source.TRACKING_CHANGES)
    )
    return true
  }
  return false
}


// This is a workaround for the issue https://gitlab.avvoka.com/avvoka/editor/-/issues/1161?work_item_iid=1179
// We can basically decide if we want to load the format from the style
// TODO: Add support for parent styles
export const getActiveFormatsFromSelection = (
  editorStore: EditorStoreType,
  editor: MountedEditor,
  selection: SuccessSelectionRange,
  includeStyles: boolean
) => {
  const extractStyleFormat = (blot: Blot) => {
    const line = ascendantBlot(blot, isScopeExactLineBlot, true)
    const styleFormat: DeltaInsertAttributes = {}

    const styleKey =
      line?.attributes['data-avv-style'] ?? editorStore.defaultStyle.key
    if (styleKey) {
      const style = editorStore.styles.formats[styleKey]
      if (style) {
        Object.assign(styleFormat, clone(style.definition))
      }
    }

    // Copy the style format to the result with the format name as key and value 1
    Object.keys(styleFormat).forEach((key) => {
      if (!result[key]) {
        result[key] = {
          count: 1,
          formats: [styleFormat[key] as DeltaInsertAttributes]
        }
      }
    })
  }

  // How many times the format is used in the selection (missing = not used)
  const result: Record<
    string,
    { count: number; formats: DeltaInsertAttributes[] }
  > = {}

  if (selection.isCollapsed) {
    const blot = selection.blotAtStart

    if (includeStyles) {
      extractStyleFormat(blot)
    }

    // Copy the format to the result with the format name as key and value 1
    const formats = bubbleFormats(blot, BubbleFormatsMode.EVERYTHING)
    Object.keys(formats).forEach((key) => {
      result[key] = {
        count: 1,
        formats: [formats[key] as DeltaInsertAttributes]
      }
    })
  } else {
    const blots = descendantBlots(
      editor.scroll,
      selection.start,
      selection.length
    )

    blots.forEach((blot) => {
      if (includeStyles) {
        extractStyleFormat(blot)
      }

      // Copy the format to the result with the format name as key and value 1
      const formats = bubbleFormats(blot, BubbleFormatsMode.EVERYTHING)
      Object.keys(formats).forEach((key) => {
        result[key] ??= { count: 0, formats: [] }
        if (
          result[key].formats.every((format) => !equal(format, formats[key]))
        ) {
          result[key].count++
          result[key].formats.push(formats[key] as DeltaInsertAttributes)
        }
      })
    })
  }

  return result
}
