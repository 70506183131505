import {
  ascendantBlot, Delta,
  getOpLength,
  hasAttributes,
  type InsertOp,
  isInsert,
  isLine,
  isRetain,
  type MountedEditor,
  type Op
} from "@avvoka/editor";
import {BitArray, Source, uniqueArray} from "@avvoka/shared";
import { getActivePinia } from "pinia";
import useDebounce from "~/features/_abstract/utils/debounce";
import {useToast} from "~/library/utils/toasts";
import { useDocumentStore } from "~/stores/generic/document.store";

/**
 * Maps special character names to their actual character values.
 * This is used to translate human-readable character names in the whitelist
 * to their actual character representations.
 */
const SPECIAL_CHARACTER_MAP: Record<string, string> = {
  'Space': ' ', 'Cr': '\r', 'Lf': '\n', 'Tab': '\t', 'Vt': '\v',
  'Ff': '\f', 'Bs': '\b', 'Esc': '\x1B', 'Del': '\x7F', 'Null': '\0',
  'Bell': '\x07', 'EOT': '\x04', 'SOT': '\x02', 'ZWSP': '​'
};
const SPECIAL_CHARACTER_MAP_INVERTED = Object.fromEntries(Object.entries(SPECIAL_CHARACTER_MAP).map(([key, value]) => [value, key]))

/**
 * Retrieves and processes the whitelist of allowed characters from the store.
 * @returns An array of allowed characters.
 */
export function _parseWhitelist(stringifiedWhitelist: string): string[] {
  return uniqueArray(stringifiedWhitelist.split(/[ \n\r]/))
    .filter(character => character !== '')
    .map(character => SPECIAL_CHARACTER_MAP[character] ?? character);
}

/**
 * Checks if a character is disallowed (not in the whitelist).
 * @param character - The character to check.
 * @param whitelist - The list of allowed characters.
 * @returns True if the character is disallowed, false otherwise.
 */
export function _isDisallowedCharacter(character: string, whitelist: string[]): boolean {
  return !whitelist.includes(character);
}

/**
 * Displays an error toast for a disallowed character.
 * @param character - The disallowed character.
 */
function showWhitelistErrorToast(characters: Set<string>): void {
  const unmappedCharacters = Array.from(characters).map((character) => SPECIAL_CHARACTER_MAP_INVERTED[character] ?? character)

  useToast({
    message: localizeText('documents.err_messages.body_cannot_contain_character', { characters: unmappedCharacters.join(', ') }),
    type: 'error',
    inverted: true
  })
}


/**
 * Displays an error toast for a body length exceeding the limit.
 * @param max - The maximum allowed body length.
 */
function showBodyLimitErrorToast(min: number, max: number, current: number): void {
  const locKey = max === Number.POSITIVE_INFINITY
    ? 'template.err_messages.invalid_body_length_no_max'
    : 'template.err_messages.invalid_body_length'

  useToast({
    message: localizeText(locKey, {min, max, current}),
    type: 'error',
    inverted: true
  });
}

export function _removeDisallowedCharactersDelta(delta: Delta<InsertOp[]>): Delta {
  const updateDelta = new Delta()
  for(const op of delta.ops) {
    if(!hasAttributes(op) || op.attributes.restrictedCharacter === undefined) {
      updateDelta.retain(getOpLength(op))
    } else {
      updateDelta.retain(getOpLength(op), { restrictedCharacter: null })
    }
  }
  return updateDelta
}


/**
 * Generates a Delta object for disallowed characters in the editor content.
 * @param ops - The operations from the editor's content.
 * @param whitelist - The list of allowed characters.
 * @returns A Delta object marking disallowed characters.
 */
export function _getDisallowedCharactersDelta(ops: Op[], whitelist: string[]): Delta {
  const updateDelta = new Delta()

  ops.forEach(op => {
    const opLength = getOpLength(op);

    if (isInsert(op) && typeof op.insert === 'string') {
      for (const character of op.insert) {
        if (_isDisallowedCharacter(character, whitelist)) {
          updateDelta.retain(1, { restrictedCharacter: {} });
        } else if (hasAttributes(op) && op.attributes.restrictedCharacter !== undefined) {
          updateDelta.retain(1, { restrictedCharacter: null })
        } else {
          updateDelta.retain(1)
        }
      }
    } else if (isRetain(op) || isInsert(op)) {
      updateDelta.retain(opLength)
    }
  });

  return updateDelta;
}

/**
 * Updates the editor status in the store based on the presence of disallowed characters.
 * @param editor - The mounted editor instance.
 * @param whitelist - The list of allowed characters.
 */
export function _updateEditorStatus(editor: MountedEditor, whitelist: string[]): void {
  const flatContent = (editor.readonlyDelta.ops as InsertOp[])
    .filter(op => typeof op.insert === 'string' && !(hasAttributes(op) && op.attributes.delete))
    .map(op => op.insert as string)
    .join('');

  const containsNonWhitelistedChars = flatContent.split('').some(char => _isDisallowedCharacter(char, whitelist));
  AvvStore.commit('SET_BODY_CONTAINS_NONWHITELISTED_CHARACTERS', containsNonWhitelistedChars);
}

/**
 * Handles the validation and marking of disallowed characters in the editor.
 * This function sets up listeners and applies necessary updates to the editor
 * to highlight and manage disallowed characters based on the whitelist.
 *
 * @param editor - The mounted editor instance to be handled.
 */
export function handleShowingDisallowedCharacters(editor: MountedEditor, stringifiedWhitelist: string): void {
  const whitelist = _parseWhitelist(stringifiedWhitelist);
  if (whitelist.length === 0) return;

  const documentStore = useDocumentStore(getActivePinia())

  const applyDisallowedCharactersDelta = () => {
    if(!documentStore.validations.character_whitelist.enabled) return;
    const updateDelta = _getDisallowedCharactersDelta(editor.scroll.getDelta().ops, whitelist);
    editor.update(updateDelta, new BitArray().set(Source.API));
  };

  if(editor.negotiation != null) {
    editor.negotiation.onConnectionChange.subscribe(({state}) => {
      if(state == 'fetched') {
        applyDisallowedCharactersDelta();
      }
    })
  }

  editor.callWheneverReady(applyDisallowedCharactersDelta);

  const debouncedUpdateStatus = useDebounce(() => _updateEditorStatus(editor, whitelist), 1000, true);

  const oldDelta = editor.scroll?.getDelta() ?? new Delta();
  editor.onChange.subscribe(({ change, source }) => {
    if(!source.check(Source.USER)) return;
    if(!documentStore.validations.character_whitelist.enabled) return;
    const selection = editor.selection.value;
    const diff = oldDelta.diff(editor.scroll.getDelta());
    const updateDelta = _getDisallowedCharactersDelta(diff.ops, whitelist);
    // Hack: If the letter before is disabled, we need to update the selection
    const rch = ascendantBlot(selection.blotAtStart!, b => b.statics.blotName === 'restrictedCharacter' as string)
    if(rch) {
      const newSelection = selection.toFresh()
      editor.selection.replaceSelection(newSelection);
    }
    editor.update(updateDelta, new BitArray().set(Source.API).set(Source.TRACKING_CHANGES));
    if(rch) {
      const newSelection = selection.toFresh()
      editor.selection.setImmediateSelection(newSelection);
    }

    const presentDisallowedCharacters = new Set<string>()

    for(const op of change.ops) {
      if(isInsert(op) && typeof op.insert === 'string') {
        for(const character of op.insert) {
          if(_isDisallowedCharacter(character, whitelist)) {
            presentDisallowedCharacters.add(character)
          }
        }
      }
    }

    if (presentDisallowedCharacters.size > 0) {
      showWhitelistErrorToast(presentDisallowedCharacters)
    }

    debouncedUpdateStatus();
  });
}

/**
 * Handles the validation of the body length in the editor
 * and displays an error toast if the length exceeds the limit.
 * @param editor - The mounted editor instance to be handled.
 * @param min - The minimum allowed body length.
 * @param max - The maximum allowed body length.
 */
export function handleBodyLimit(editor: MountedEditor): void {
  const documentStore = useDocumentStore(getActivePinia());
  const { min, max = Number.POSITIVE_INFINITY } = documentStore.validations.body_character_limit;
  
  const isAnyLimitSet = min !== 0 || max !== Number.POSITIVE_INFINITY;
  if (!isAnyLimitSet) return;

  editor.onChange.subscribe(({ change, source }) => {
    if (!documentStore.validations.body_character_limit.enabled) {
      return
    }
    
    if(!source.check(Source.USER)) return;
    const length = editor.scroll.getDelta().ops.reduce((acc, op) => {
      if(isLine(op)) return acc;
      return acc + getOpLength(op);
    }, 0)

    if (!max) return

    if (length > max) {
      showBodyLimitErrorToast(min, max, length);

      // Allow only retain and delete operations
      if(change.ops.some(op => isInsert(op))) {
        // Prevent further changes
        editor.update(change.invert(editor.getDelta()), new BitArray().set(Source.API).set(Source.TRACKING_CHANGES));
      }
    } else if (length < min) {
      showBodyLimitErrorToast(min, max, length);
    }
  })
}
